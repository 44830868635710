<template>
  <van-dialog v-model="showDialog" :title="title" :show-confirm-button="false">
    <van-form class="from" style="margin: 16px 10px;" input-align="left">
      <div v-if="tips" class="tips">{{tips}}</div>
      <van-field v-model="password" label="项目密码" required placeholder="请输入项目密码" type="password" />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" color="#849EFB" class="up" @click="verifySubmit">验证</van-button>
      </div>
      <div style="margin: 16px;">
        <van-button round block type="default" native-type="submit" class="up" @click="close">取消</van-button>
      </div>
    </van-form>
  </van-dialog>
</template>

<script>
import {Toast} from "vant";
import {mapActions} from "vuex";
import {
  verifyProject
} from '@/api/api'
export default {
  name: "projectVerify",
  components: {
  },
  props: {
    afterVerify: null,
    closeVerify: null,
    doctorId: '',
    projectId: '',
    title: '',
    tips: '',
  },
  watch: {
  },
  data() {
    return {
      showDialog: true,
      password: '',
    };
  },
  created() {
    //console.log(this.doctorId, this.projectId)
  },
  methods: {
    close() {
      if (this.closeVerify) {
        this.closeVerify();
      }
    },
    verifySubmit() {
      if (!this.password) {
        Toast('密码不能为空');
        return false;
      }
      let loading = Toast.loading({duration:0,forbidClick:true,message:'提交中...'});
      verifyProject({doctor_id:this.doctorId,project_id:this.projectId, password:this.password})
          .then((res)=>{
            loading.clear();
            Toast('验证通过');
            if (this.afterVerify) {
              this.afterVerify();
            }
          }).catch(err => {
            loading.clear();
            Toast(err.response.data.message);
          });
    },
  },
}
</script>

<style scoped>

</style>