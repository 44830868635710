<template>
    <!-- <van-popup v-model="show" class="dialog" :style="{ height: '231px' ,width:'327px'}" round @click-overlay="onclose"> -->
    <van-popup v-model="show" class="dialog" round @click-overlay="onclose">
        <img class="img" :src="img" />
        <p class="title">{{title}}</p>
        <p class="remark">{{remark}}</p>
        <van-button class="dialogBtn" round @click="onClick">重新提交</van-button>
    </van-popup>
</template>

<script>
    export default {
        props: ['status', 'dialogRemark'],
        name: '',
        data() {
            return {
                show: true,

            }
        },
        computed: {
            img() {
                if (this.status == 'error') {
                    return '/static/dialogError2.png'
                } else if (this.status == 'success') {
                    return '/static/dialogSuccess.png'
                } else {
                    return '/static/dialogLoading.png'
                }
            },
            title() {
                if (this.status == 'error') {
                    return '审核不通过'
                } else if (this.status == 'success') {
                    return '审核已通过'
                } else {
                    return '审核中'
                }
            },
            remark() {
                if (this.dialogRemark) {
                    return this.dialogRemark
                } else if (this.status == 'error') {
                    return '驳回原因：xxxxxxx请修改资料重新提交'
                } else if (this.status == 'success') {
                    return '您的审核已经通过'
                } else {
                    return '审核中，请等待'
                }
            },
        },
        methods: {
            onclose() {
                // console.log("click-overlay",111111);
                this.$emit('close')
            },
            onClick() {
                this.$emit('close')
                this.show = false
                if (this.status == 'error') {
                    this.$emit('confirm')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dialog {
        overflow: initial;
        position: absolute;
        padding: 15px;
        width: 80%;
        height: auto;
        .img {
            height: 100px;
            width: 100px;
            margin-top: -36px;
        }

        .dialogBtn {
            width: 280px;
            height: 44px;
            // margin: auto;
            // background: #272727;
            background: #4B99FF;
            border-radius: 22px;
            font-size: 15px;
            color: #FFFFFF;
            line-height: 36px;
            font-family: PingFangSC-Medium;
        }

        .title {
            font-size: 16px;
            // font-weight: bold;
            font-weight: 500;
            // color: #272727;
            text-align: center;
            line-height: 24px;

        }

        .remark {
            font-size: 12px;
            font-weight: bold;
            color: #AEACAC;
            margin: 1em 1em 35px;
            word-break: break-all;
            font-family: PingFangSC-Regular;
        }
    }
</style>