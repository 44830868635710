<template>
  <div class="sub_project">
    <!-- <van-search v-model="value" placeholder="搜索项目名称" shape="round" class="search" @input="onSearch" /> -->
    <div class="page_bg">
      <div class="proj_name_text">{{ proj_name }}</div>
    </div>
    <div class="projectList">
      <!-- <div class="projectOne" v-for="(item,index) in list" :key="index" @click="next(item)"> -->
      <!-- 去掉这里的click，否则下面按钮的重签逻辑就有被跳过的可能 -->
      <div class="projectOne" v-for="(item, index) in list" :key="index">
        <!-- <div class="pic">
                    {{item.number}}
                </div> -->
        <div class="content">
          <!-- <div class="title">{{item.name}}</div>
                    <div class="status">
                        <img :src="statusImg(item.audit_status)" />
                        <span :style="{color:getColor(item.audit_status)}">{{item.audit_status}}</span>
                    </div> -->

          <div class="icon_box">
            <img v-if="item.audit_status == '资质认证成功' || item.audit_status == '资质认证中'" src="/static/under_or_pass.png" alt="" />
            <img v-else-if="item.audit_status == '资质认证失败'" src="/static/certification_error.png" alt="" />
            <img v-else-if="item.audit_status == '资质认证待完成'" src="/static/certification_wait.png" alt="" />
            <img v-else src="/static/no_pass.png" alt="" />
          </div>
          <div class="status_text">
            {{ item.audit_status }}
          </div>
          <div class="pass-time" v-if="item.first_auth_date">资质提交时间：{{ item.first_auth_date }}</div>
          <div class="status_tips" v-if="item.audit_status === '资质认证中'">资质认证审核为1-2个工作日， 后续登录请从项目原入口进入</div>
        </div>
        <!-- <div>{{item.audit_status }}</div> -->
      </div>
      <!-- resign_status：0 不用管(进入病例中心)  1-待重签  2-审核通过  3-审核驳回  4-等待审核 service_agreement_reason:不通过原因 -->
      <div class="btn_box" v-for="item in list" :key="item.id">
        <template v-if="item.audit_status != '资质认证中'">
          <template v-if="item.audit_status == '资质认证成功'">
            <template v-if="doctorExamineInfo.resign_status == '1'">
              <van-button round type="info" class="entrance_btn" size="large" @click="goToExamine(item)">重新签署服务协议</van-button>
            </template>
            <template v-else-if="doctorExamineInfo.resign_status == '4'">
              <van-button round disabled color="#cfcfcf" type="primary" class="entrance_btn" size="large">协议正在审核中～</van-button>
            </template>
            <template v-else-if="doctorExamineInfo.resign_status == '3'">
              <van-button round type="info" class="entrance_btn" size="large" @click="goToExamine(item)">重新签署服务协议</van-button>
            </template>
            <template v-else>
              <van-button round type="info" class="entrance_btn" size="large" @click="next(item)">进入病例中心</van-button>
            </template>
          </template>

          <template v-else>
            <van-button round type="info" class="entrance_btn" size="large" @click="next(item)">
              {{ item.audit_status == '资质认证失败' ? '重新认证' : item.audit_status == '资质认证待完成' ? '立即认证' : item.audit_status }}
            </van-button>
          </template>
          <!-- <van-button round type="info" class="entrance_btn" size="large">
                        {{ item.audit_status == '资质认证成功' ? '进入病例中心' : (item.audit_status == '资质认证失败' ? '重新认证' : (item.audit_status == '资质认证待完成' ? "立即认证" : item.audit_status))}}
                    </van-button> -->
        </template>

        <!-- <van-button  v-if="item.audit_status != '资质认证中'" round type="info" class="entrance_btn" size="large">
                    {{ item.audit_status == '资质认证成功' ? '进入病例中心' : (item.audit_status == '资质认证失败' ? '重新认证' : (item.audit_status == '资质认证待完成' ? "立即认证" : item.audit_status))}}
                </van-button> -->
      </div>
    </div>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
    <DialogRooom :status="dialogStatus" v-if="showDialog" :dialogRemark="dialogRemark" @confirm="onConfirm" @close="onClose"></DialogRooom>
    <projectVerify v-if="showProjectVerify" :title="'请输入项目密码'" :doctorId="userId" :projectId="verifyProjectId" :closeVerify="closeVerify()" :afterVerify="afterVerify()"></projectVerify>

    <mobileVerify v-if="showVerify && !this.doctorInfo.has_empower" :ifShowBank="ifShowBank" :showBankcardPhoto="ifShowBankcardPhoto" :projectId="verifyProjectId" :afterModifyMobile="afterModifyMobile" :doctorId="userId" :bankcard="this.doctorInfo.bankcard" :resetDoctorInfo="true" :title="'实名制验证'" :tips="'实名制手机号需和银行卡预留手机号一致'" :tips_remain="'认证次数剩余' + valid_times + '次'" @hideVerify="hideVerify"></mobileVerify>
  </div>
</template>

<script>
import DialogRooom from '@/components/dialog.vue'
import projectVerify from '@/components/projectVerify.vue'
import { Toast, Dialog } from 'vant'
import { getSubProject, record, getLinkScheme, recordCanSubmit, doctor, projectDoctor } from '@/api/api'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import mobileVerify from '@/components/mobileVerify.vue'
export default {
  name: '',
  components: {
    DialogRooom,
    projectVerify,
    mobileVerify
  },
  data() {
    return {
      needProjectVerify: false,
      showProjectVerify: false,
      verifyProjectId: null,
      afterVerifyArgs: null,
      showDialog: false,
      value: '',
      dialogStatus: '',
      dialogRemark: '',
      projectList: [],
      showVerify: false,
      mobile_verify_position: null,
      ifShowBank: false, //是否显示开户行及开户支行
      ifShowBankcardPhoto: false,//是否显示上传银行卡照片
      short_name: '', //项目缩写
      project_email: '',
      doctorExamineInfo: {},
      idsn_verify: ''
    }
  },
  computed: {
    list() {
      // let data = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
      //     'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
      // ]
      // for (let i in this.projectList) {
      //     for (let j in data) {
      //         if (i % data.length == j) {
      //             this.projectList[i].number = data[j]
      //         }
      //     }
      // }
      // console.log("list:",this.projectList)
      return this.projectList
    },
    ...mapGetters(['project_id']),
    ...mapState(['sub_id', 'userId', 'doctorInfo', 'record_show_date', 'basicInformation', 'valid_times', 'proj_name'])
  },
  mounted() {
    console.log('剩余次数@:', this.valid_times)
    console.log('实名认证弹出位置', this.basicInformation)
    console.log('实名认证弹出位置(1,2)', this.basicInformation.subprojects[0].mobile_verify_position)
    this.mobile_verify_position = this.basicInformation.subprojects[0].mobile_verify_position
    this.short_name = this.basicInformation.projects[0].short_name
    this.project_email = this.basicInformation.subprojects.find(item => item.id == this.sub_id)?.project_email
    // if(this.short_name == 'llsheq' || this.short_name == 'linai' || this.short_name == 'fcah' || this.short_name == 'bzky'){ //例例生辉二期、鳞癌长疗程项目（新征程）实名制弹窗显示开户行和开户支行
    //     this.ifShowBank = true
    // }
    if (this.basicInformation.subprojects[0].is_bankname_verify == 1) {
      this.ifShowBank = true
    }
    if (this.basicInformation.subprojects[0].is_collection_bankcard_photo == 1) {
      this.ifShowBankcardPhoto = true
    }
    this.clearImgList()
    this.clearIdcardList()
    this.SET_IDS({
      project_id: this.project_id,
      sub_id: null
    })
    if (this.doctorInfo && this.doctorInfo.login_project_id == 0) {
      this.needProjectVerify = true
    }
    this.init()
    console.log('project_id', this.project_id)
  },
  methods: {
    goToExamine(item) {
      this.$router.replace({
        path: '/re_agreement',
        query: {
          project_doctor_id: this.doctorExamineInfo.id
          // dire: 'front'
        }
      })
    },
    // 关闭验证弹窗
    hideVerify(is_hide) {
      this.showVerify = is_hide
      console.log('关闭验证弹窗', is_hide)
    },
    ...mapMutations(['clearImgList', 'clearIdcardList', 'setValidTimes', 'setValidApply']),
    ...mapActions(['SET_IDS', 'SET_DOCTOR_ID', 'SET_DOCTOR_INFO']),
    onClose() {
      this.showDialog = false
    },
    onConfirm() {
      this.$router.replace({
        path: '/add_hospital'
      })
    },
    async init(value) {
      //获取医生是否需要重新签署劳动协议 resign_status:0 不用管  1-待重签  2-等待审核  3-审核驳回  4-审核通过 service_agreement_reason:不通过原因
      this.doctorExamineInfo = await projectDoctor()
      console.log(this.doctorExamineInfo, 'this.doctorExamineInfo')
      this.idsn_verify = this.doctorExamineInfo.idsn_verify
      if(this.idsn_verify === '更新实名信息') {
        Dialog.confirm({
          message: '为了您更好的体验，根据项目要求，需要您更新身份证信息',
          confirmButtonColor: '#4B99FF',
          title: '提示'
        }).then(() => {
          // 重新上传身份证信息
          this.$router.replace({
            path: '/updateOcr',
            query: {
              doctor_id: this.doctorExamineInfo.doctor_id,
              id: this.doctorExamineInfo.id
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.$router.replace({
            path: '/project'
          })
        })
      }
      this.projectList = await getSubProject({
        params: {
          project_id: this.project_id,
          name: value ? value : '',
          doctor_id: this.userId,
          enable: 1
        }
      })
    },
    async onSearch() {
      this.init(this.value)
    },
    async onCancel() {
      this.init()
    },
    getColor(audit_status) {
      if (audit_status == '资质认证中') {
        return '#556EEE'
      } else if (audit_status == '尚未资质认证') {
        return '#A8A8A8'
      } else if (audit_status == '资质认证失败') {
        return '#FF5D5D'
      } else if (audit_status == '资质认证成功') {
        return '#1EC616'
      } else {
        return '#FF923A'
      }
    },
    statusImg(audit_status) {
      if (audit_status == '资质认证中') {
        return '/static/loading.png'
      } else if (audit_status == '尚未资质认证') {
        return '/static/notYet.png'
      } else if (audit_status == '资质认证失败') {
        return '/static/error.png'
      } else if (audit_status == '资质认证成功') {
        return '/static/success.png'
      } else {
        return '/static/wait.png'
      }
    },
    statusIcon(audit_status) {
      if (audit_status == '资质认证中') {
        return '/static/loadingPic.png'
      } else if (audit_status == '尚未资质认证') {
        return '/static/notYetPic.png'
      } else if (audit_status == '资质认证失败') {
        return '/static/errorPic.png'
      } else if (audit_status == '资质认证成功') {
        return '/static/successPic.png'
      } else {
        return '/static/waitPic.png'
      }
    },
    async next(item) {
      // if (item.can_apply != 1) {
      //     Dialog.alert({
      //         message: '该医生不属于本项目服务范围，若有疑问请联系项目负责人员。',
      //         title: '非本项目服务范围',
      //         confirmButtonColor: '#02BB00',
      //         confirmButtonText: '我知道了'
      //     }).then(() => {

      //     });
      //     return false;
      // }
      console.log('查看状态')
      console.log('item', item)
      await this.SET_IDS({
        project_id: this.project_id,
        sub_id: item.id
      })
      if (item.audit_status == '资质认证中') {
        Toast('请耐心等待')
        return false
      } else if (item.audit_status == '资质认证失败') {
        this.dialogStatus = 'error'
        this.dialogRemark = item.audit_remark
        this.showDialog = true
      } else if (item.audit_status == '资质认证成功') {
        await this.SET_DOCTOR_ID(item.project_doctor_id)
        let result = await doctor({ params: { doctor_id: this.userId } }) //刷新医生信息
        // console.log("result99999999999",result);
        await this.SET_DOCTOR_INFO(result) //存储医生信息
        await this.setValidApply(result.verify) //存储是否可以显示人工申请通道状态(1显示)
        if (this.mobile_verify_position == '1') {
          let res = await projectDoctor() //获取can_mobile_verify和is_mobile_verify
          console.log('!', res)
          console.log('!!', this.doctorInfo)
          this.setValidTimes(res.bankcard_api_call_count)
          console.log('剩余次数_存：', this.valid_times)
          let verifyFlag = !this.doctorInfo || res.is_mobile_verify != 1
          if (res.can_mobile_verify == true && !this.doctorInfo.has_empower && verifyFlag) {
            //四要素验证
            this.showVerify = true
            return
          }
        }
        let res = await projectDoctor()
        console.log('项目医生msg:', res.informed_consent)
        if (this.short_name == 'linai' && !res.informed_consent) {
          //鳞癌长疗程，若没阅读知情同意书跳转到知情同意书阅读页面（只跳转一次）
          console.log('鳞癌长疗程跳转到知情同意书阅读页面')
          this.$store.commit('setRecordShowDate', item.record_show_date)
          this.$router.push({
            name: 'informedConsent',
            query: {
              project_doctor_id: item.project_doctor_id
            }
          })
          return
        }
        if (this.needProjectVerify) {
          this.verifyProjectId = this.project_id
          this.showProjectVerify = true
          this.afterVerifyArgs = item
          return
        }
        if (item.need_informed_consent == 1 && item.audit_status != '资质认证成功') {
          this.$router.replace({
            path: '/add_agreement',
            query: {
              project_doctor_id: item.project_doctor_id,
              follow_submit: 1
            }
          })
        } else {
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true
          })
          let { items } = await record()
          console.log(items, 'recordItem')
          console.log('item', item.record_show_date)
          this.$store.commit('setRecordShowDate', item.record_show_date)
          if (items.length > 0) {
            this.$router.replace({
              path: '/progress'
            })
            Toast.clear()
          } else {
            if (this.short_name == 'sshy' && this.doctorExamineInfo.is_create_record != 1) {
              Dialog.alert({
                message: `您好，您在本项目上传的病例数已到上限，详情请联系项目办。${this.project_email ? '<br>项目官员邮箱：' + this.project_email : ''}`,
                confirmButtonColor: '#4B99FF'
              }).then(() => {})
              Toast.clear()
              return
            }
            let data = await getLinkScheme({
              params: {
                project_doctor_id: item.project_doctor_id
              }
            })
            // console.log('data', data)
            if (!data.scheme_id && !data.subscheme_id) {
              if (this.short_name == 'fcah') {
                //肺肠安好项目在选择方案前需要预填信息
                this.$router.replace({
                  name: 'preFilling'
                })
                Toast.clear()
                // }else if(this.short_name == 'bxzleq') { //靶向治疗二期跳过方案选择直接到病例中心页面(1759需求取消该限制)
                //     this.$router.replace({
                //         name: 'Progress'
                //     })
                //     Toast.clear()
              } else {
                Toast.clear()
                this.$router.replace({
                  path: '/list'
                })
              }
            } else {
              let reqData = {
                project_doctor_id: item.project_doctor_id
              }
              if (data.subscheme_id) {
                reqData.subscheme_id = data.subscheme_id
              } else if (data.scheme_id) {
                reqData.scheme_id = data.scheme_id
              }
              await recordCanSubmit(reqData)
                .then(res => {
                  if (res.can) {
                    this.goCase(data)
                    Toast.clear()
                  } else {
                    Toast.clear()
                    Toast('项目上传病例数已达上限。')
                  }
                })
                .catch(err => {
                  this.disabled = false
                  Toast.clear()
                  Toast(err.response.data.message)
                })
            }
          }
        }
      } else {
        // 若在点击资质认证待完成时弹出实名制弹窗
        // console.log("弹窗位置——2",this.mobile_verify_position)
        // if(this.mobile_verify_position == "2"){  //弹出实名制认证弹窗
        //     let res=await projectDoctor()//获取can_mobile_verify和is_mobile_verify
        //     console.log("!",res)
        //     console.log("!!",this.doctorInfo)
        //     this.setValidTimes(res.bankcard_api_call_count)
        //     console.log("剩余次数_存：",this.valid_times)
        //     let verifyFlag=!this.doctorInfo || res.is_mobile_verify != 1
        //     if(res.can_mobile_verify==true&&!this.doctorInfo.has_empower&& verifyFlag){//四要素验证
        //         this.showVerify=true
        //         return
        //     }
        // }
        this.$router.replace({
          path: '/add_hospital'
        })
      }
    },
    goCase(data) {
      this.$router.replace({
        path: '/case',
        query: {
          scheme_id: data.scheme_id,
          subscheme_id: data.subscheme_id ? data.subscheme_id : '',
          enable: 1
        }
      })
    },
    back() {
      this.$router.replace({
        path: '/project'
      })
    },
    afterModifyMobile() {
      this.showVerify = false
    },
    closeVerify() {
      this.showProjectVerify = false
    },
    afterVerify() {
      this.showProjectVerify = false
      this.needProjectVerify = false
      this.next(this.afterVerifyArgs)
    }
  }
}
</script>

<style lang="scss" scoped>
.sub_project {
  // height: 100%;
  // background: url(/static/proj_center_bg.png);
  .page_bg {
    width: 100%;
    height: 176px;
    background: url(/static/proj_center_bg.png);
    background-size: cover;
    .proj_name_text {
      font-size: 22px;
      color: #ffffff;
      font-family: PingFangSC-Medium;
      // background: #666;
      padding-top: 51px;
      padding-left: 28px;
      text-align: left;
    }
  }
  .search {
    margin-bottom: 10px;
  }

  .projectList {
    height: 100%;
    width: 100%;
    padding: 0 11px;
    box-sizing: border-box;
    // display: flex;
    // justify-content: center;
    .btn_box {
      padding: 0 5%;
      .entrance_btn {
        font-size: 16px;
        color: #ffffff;
        font-family: PingFangSC-Medium;
        background: #4b99ff;
      }
    }
    .projectOne {
      margin: -69px auto 0;
      width: 90%;
      // min-height: 56px;
      height: 202px;
      background: #ffffff;
      // box-shadow: 0px 3px 10px 0px rgba(175, 175, 175, 0.76);
      box-shadow: 0px 4px 12px 0px rgba(22, 23, 24, 0.08);
      border-radius: 11px;
      display: flex;
      // padding: 6px 10px 6px 17px;
      box-sizing: border-box;
      align-items: center;
      margin-bottom: 19px;

      .pic {
        width: 35px;
        height: 35px;
        background: #556eee;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        line-height: 35px;
      }

      .content {
        // padding-left: 14px;
        box-sizing: border-box;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        flex: 1;
        text-align: left;
        .status_text {
          text-align: center;
          margin-top: 18px;
          font-size: 15px;
          color: #292929;
          font-family: PingFangSC-Medium;
        }
        .icon_box {
          img {
            width: 91px;
            height: 91px;
            display: flex;
            margin: 0 auto;
          }
        }
        .title {
          font-size: 14px;
          color: #272727;
          line-height: 1.2;
        }
        .status_tips {
          font-size: 12px;
          color: #666;
          line-height: 1.2;
          text-align: center;
          margin: 4px 15%;
        }
        .pass-time {
          font-size: 12px;
          color: #666;
          line-height: 1.2;
          text-align: center;
          margin-top: 4px;
        }
        .status {
          display: flex;
          align-items: center;

          img {
            width: 39px;
            height: 12px;
            margin-right: 5px;
          }

          span {
            font-size: 11px;
            color: #556eee;
          }
        }
      }

      .icon {
        height: 13px;
        width: 13px;

        img {
          display: block;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
